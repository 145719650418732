import React from 'react';

const Privacy = () => {

	return (
		<section id='main-content' className='page personvern-page'>
			<h2>Personvernerklæring</h2>
			<section>
				<h3>Personvernerklæring for Aktivitetforalle</h3>
				<div>
					<p>Denne personvernerklæringen beskriver hvordan Aktivitetforalle ("vi", "oss", eller "vår") samler inn, bruker og deler informasjon når du bruker vår Facebook-applikasjon («Appen»).</p>

					<h4>Informasjon Vi Samler Inn</h4>
					<p>Når du bruker vår App, kan vi samle inn visse opplysninger fra eller om deg. Denne informasjonen kan inkludere:</p>
					<ol>
						<li>Facebook Profilinformasjon: Når du logger inn på vår App ved hjelp av Facebook-innlogging, kan vi samle inn informasjon som du har gjort offentlig tilgjengelig på Facebook, som navnet ditt, profilbildet ditt, kjønn og annen informasjon du velger å gjøre offentlig tilgjengelig på din Facebook-profil.</li>
						<li>Arrangementinformasjon: Vår App kan få tilgang til arrangementer fra din offentlige Facebook-profil. Dette inkluderer arrangementer du har opprettet, deltatt på, eller har blitt invitert til.</li>
					</ol>

					<h4>Hvordan Vi Bruker Din Informasjon</h4>
					<p>Vi kan bruke informasjonen vi samler inn fra deg til følgende formål:</p>
					<ol>
						<li>For å Tilby og Forbedre Våre Tjenester: Vi bruker informasjonen til å tilby og vedlikeholde vår App, autentisere brukere og tilpasse opplevelsen din.</li>
						<li>For å Kommunisere Med Deg: Vi kan bruke e-postadressen din eller annen kontaktinformasjon til å kommunisere med deg om våre tjenester, svare på dine henvendelser og sende deg viktige oppdateringer og varsler.</li>
					</ol>

					<h4>Deling av Informasjon</h4>
					<p>Vi kan dele din informasjon i følgende tilfeller:</p>

					<ol>
						<li>Med Tjenesteleverandører: Vi kan dele din informasjon med tredjeparts tjenesteleverandører som hjelper oss med å drive vår App og levere tjenester på våre vegne.</li>
						<li>For Juridiske Formål: Vi kan avsløre din informasjon som svar på lovlige henvendelser fra offentlige myndigheter, inkludert for å møte nasjonale sikkerhets- eller håndhevingskrav.</li>
					</ol>

					<h4>Dine Valg</h4>
					<p>Du kan kontrollere informasjonen du gir oss og hvordan den brukes på følgende måter:</p>
					<ol>
						<li>Facebook-innstillinger: Du kan administrere informasjonen som deles med vår App gjennom dine Facebook-innstillinger og tillatelser.</li>
						<li>Velg Bort: Du kan velge å ikke gi visse opplysninger eller velge bort visse datainnsamlinger ved å ikke bruke vår App.</li>
					</ol>

					<h4>Datasikkerhet</h4>
					<p>Vi tar rimelige tiltak for å beskytte informasjonen vi samler inn mot tap, misbruk og uautorisert tilgang, avsløring, endring og ødeleggelse.</p>

					<h4>Endringer i Dette Personvernreglementet</h4>
					<p>Vi kan oppdatere denne personvernerklæringen fra tid til annen. Eventuelle endringer vil være effektive når vi legger ut den reviderte politikken på denne siden.</p>

					<h4>Kontakt Oss</h4>
					<p>Hvis du har spørsmål om denne personvernerklæringen eller våre data praksis, vennligst kontakt oss på support@zpirit.no.</p>
				</div>
			</section>

		</section>
	);
};

export default Privacy;